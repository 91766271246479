<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-8 col-xxl-8" ref="withdraw">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">New withdrawal</h4>
                        </div>
                        <div class="card-body">
                            <div class="col-12 mt-3">
                                <label class="col-form-label font-weight-bold">Enter an amount</label>
                                <input v-model.number="withdraw.amount" type="number" class="form-control" placeholder="amount">
                            </div>
                            <div class="col-12 mt-3">
                                <label class="col-form-label font-weight-bold">Select a wallet to make payment to</label>
                                <select class="form-control" v-model="wallet">
                                    <template v-if="Object.entries(getUser.data.wallets).length > 0">
                                        <optgroup :label="key" v-for="(val, key, idx) in getUser.data.wallets" :key="'cr_'+idx">
                                            <option :value="key+'-'+value2" v-for="(value2, idx2) in val" :key="'walt_'+idx2">
                                                {{value2}}
                                            </option>
                                        </optgroup>
                                    </template>
                                    <template v-else>
                                        <option value="">Empty wallet list</option>
                                    </template>
                                </select>
                                <small id="emailHelp" class="form-text text-muted">You can add a wallet from your profile link.</small>
                            </div>
                            <div class="col-12 mt-3 text-center">
                                <button @click.prevent="validateInput" class="btn btn-outline-info">Make Withdrawal</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Withdrawal from "../../../models/withdrawal";
import {mapGetters} from 'vuex';

export default {
    name: "Withdrawal",
    data(){
        return {
            withdraw: (new Withdrawal()).data
        }
    },
    computed: {
        ...mapGetters('user', ['getUser']),
        wallet: {
            get(){
                return `${this.withdraw.cryptoID}-${this.withdraw.wallet}`
            },
            set(plan){
                const withdraw_arr = plan.split('-');
                this.withdraw.cryptoID = withdraw_arr[0];
                this.withdraw.wallet = withdraw_arr[1]
            }
        }
    },
    methods: {
        async addNewWithdrawal(){
            const loader = this.$loading.show({container: this.$refs.withdraw});
            let response = await this.$store.dispatch('user/getUser', this.$store.getters['user/getUser'].id);
            if(response.status){
                if(this.withdraw.amount > response.data.data.balance){
                    toastr.warning("You have insufficient funds to complete this operation")
                }else if(this.withdraw.amount > 0){
                    response = await this.$store.dispatch('withdrawal/addWithdrawal', this.withdraw);
                    if(response.status){
                        loader.hide();
                        toastr.success("Operation successful");
                        return this.$router.push({name: 'Withdrawals'})
                    }else{
                        toastr.error(response.message)
                    }
                }else{
                    toastr.warning("Enter a valid amount")
                }
            }
            loader.hide();
        },
        validateInput(){
            if(typeof this.withdraw.amount !== 'number'){
                return toastr.warning("Invalid withdrawal amount")
            }
            if(!this.withdraw.wallet){
                return toastr.warning("Select a wallet for payment")
            }
            return this.addNewWithdrawal();
        }
    }
}
</script>

<style scoped>

</style>